<template>
  <div class="buy-detaile">
    <common-header title="我的社区" />
    <ul class="tab-list">
      <li
        @click="handleChange(item)"
        :class="{
          'tab-list-item': 'tab-list-item',
          'active-tab-list-item':
            currentId === item.id ? 'active-tab-list-item' : '',
        }"
        v-for="item in tableList"
        :key="item.id"
      >
        {{ item.name }}
      </li>
    </ul>
    <CumulativeCard title="累计：" :amount="amountSum" />
    <div class="account-table">
      <h4>明细</h4>
      <van-list
        class="account-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="table-title">
          <p class="time">时间</p>
          <p class="comput-power">FIL</p>
          <p class="address">地址</p>
        </div>
        <div class="table-title-item" v-for="item in list" :key="item.address">
          <p class="time">{{ item.time }}</p>
          <p class="comput-power">
            {{ !currentId ? item.selfAmount : item.teamAmountSum }}
          </p>
          <p class="type">
            {{ item.address.slice(0, 8) + "****" + item.address.slice(-6) }}
          </p>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import CommonHeader from "../../components/CommonHeader.vue";
import CumulativeCard from "../../components/CumulativeCard.vue";
import { teamAwardDetail } from "../../server";
import * as timeago from "timeago.js";
export default {
  name: "MyCommunity",
  components: { CommonHeader, CumulativeCard },
  data() {
    return {
      tableList: [
        { id: 0, name: "全部" },
        { id: 1, name: "V1" },
        { id: 2, name: "V2" },
        { id: 3, name: "V3" },
      ],
      currentId: 0,
      loading: false,
      finished: true,
      current: 1,
      amountSum: 0,
      list: [],
    };
  },

  methods: {
    async handleChange(item) {
      this.currentId = item.id;
      await this.getTeamCommunity();
    },
    async onLoad() {
      this.current = this.current + 1;
    },
    async getTeamCommunity() {
      const data = await teamAwardDetail(
        this.getAddress,
        this.currentId,
        this.current
      );
      this.amountSum = data.amountSum;
      this.list = data.list.map((item) => {
        return {
          ...item,
          time: timeago.format(item.time, "zh_CN"),
        };
      });
    },
  },
  async created() {
    await this.getTeamCommunity();
  },
};
</script>

<style lang="less" scoped>
.buy-detaile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .tab-list {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 343px;
    height: 50px;
    background: #161f2c;
    box-shadow: 0px 0px 10px rgba(52, 52, 52, 0.08);
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15px;
    overflow-x: scroll;
    .tab-list-item {
      width: 153px;
      height: 40px;
      opacity: 1;
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      color: #9aaabf;
    }
    .active-tab-list-item {
      color: #ffffff;
      background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
      box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
    }
  }
  .account-table {
    width: 345px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    h4 {
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #e9ecf0;
    }
    .account-list {
      margin-bottom: 30px;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      width: 345px;
      height: 306px;
      background: #161f2c;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;

      overflow-y: scroll;
      .table-title,
      .table-title-item {
        flex-shrink: 0;
        width: 100%;
        height: 37px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #263243;
        font-size: 12px;
        font-family: "PingFang SC";
        font-weight: 400;
        color: #c1c1c1;
        .time {
          width: 122px;
        }
        .comput-power {
          width: 72px;
        }
        .address {
          flex-grow: 1;
        }
      }
      .table-title-item {
        .comput-power {
          color: #019969;
        }
        .time,
        .address {
          color: #e9ecf0;
        }
      }
    }
  }
}
</style>
